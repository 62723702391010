@import url(https://fonts.googleapis.com/css?family=Roboto);
html {
  font-size: 62.5%;
  height: 100%;
}

ul {
  padding-inline-start: 0;
}

body {
  /* font-family: 'Roboto' sans-serif; */
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  color: #000000;
  height: 100%;
  font-weight: 300;
}

p,
input,
label,
a,
span {
  font-size: 1.4rem;
}

p {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

.fa .fa-fire {
  color: red;
}

#root {
  height: 100%;
}

/*Bootstrap overrites*/
.navbar-light .navbar-nav .nav-link:hover {
  color: #00008b;
}
.form-control {
  font-size: 1.4rem;
}

.grecaptcha-badge {
  opacity: 0;
}

.introjs-tooltip {
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .dropdown-menu-item:hover {
    background-color: transparent;
  }
  .dropdown-item:hover {
    background-color: transparent;
  }
}

